import React, { useState, useEffect } from 'react';
import {
  AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, ListItemIcon, Collapse,
  Menu, MenuItem, Box, Link as MuiLink
} from '@mui/material';
import {
  Menu as MenuIcon, Home as HomeIcon, Info as InfoIcon, Build as ServicesIcon, ContactMail as ContactIcon,
  VpnKey as VpnKeyIcon, KeyboardArrowDown as KeyboardArrowDownIcon,
  Facebook,
  YouTube,
  Instagram,
  Twitter,
  Phone,
  WhatsApp,
  Email,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import axios from 'axios'; // Import axios for making API requests
import useScrollTrigger from '@mui/material/useScrollTrigger';
import logo from '../assets/pra_logo.jpg'; // Assuming the logo is in the assets folder

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [aboutOpen, setAboutOpen] = useState(false); // For mobile About menu expand
  const [contactDetails, setContactDetails] = useState(null); // State to hold contact details

// Fetch contact details from API
useEffect(() => {
  //axios.get('http://localhost:5000/api/contact-details')
  //24-10-24//
  axios.get('https://apiviztech.in:6100/api/contact-details')
    .then(response => {
      setContactDetails(response.data); // Set the fetched contact details
    })
    .catch(error => {
      console.error('There was an error fetching the contact details!', error);
    });
}, []);

  // Handle drawer open/close
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  // Handle menu open/close
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Sticky Navbar when scrolling down
  const trigger = useScrollTrigger();
  const handleAboutClick = () => setAboutOpen(!aboutOpen); // Toggle mobile About submenu
  return (
<>

 {/* Combined Box for Contact Details and Social Icons */}
 <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' }, // Stack on small screens, row on medium and up
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: { xs: '0px', md: '0px 0px' }, // Adjust padding for smaller screens
          background: '#f0f0f0',
        }}
      >
        {/* Contact Details */}
        <Box sx={{ display: 'flex', gap: 1 }}>
        {contactDetails && (
            <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Phone sx={{ fontSize: '1.2rem' }} />
            <Typography variant="body2" sx={{ marginLeft: '0px' }}>{contactDetails.mobile_number}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <WhatsApp sx={{ fontSize: '1.2rem' }} />
            <Typography variant="body2" sx={{ marginLeft: '0px' }}>{contactDetails.whatsapp_number}</Typography>
          </Box>
          <Box sx={{ display: 'flex', 
          alignItems: 'center', 
          flexWrap: 'wrap',         // Ensures the email wraps on smaller screens
          maxWidth: '100%',         // Makes sure the box doesn't exceed screen width
          overflowWrap: 'break-word', // Allows long email addresses to break if necessary 
          }}>
            <Email sx={{ fontSize: '1.2rem',marginRight:'8px' }} />
            <Typography variant="body2" sx={{ marginLeft: '0px',
              wordBreak: 'break-all', // Break long strings of text to fit into smaller screens
              maxWidth: '100%'        // Ensures text takes up only available space
             }}>
              {contactDetails.email}</Typography>
          </Box>
          </>
          )}
        </Box>

        {/* Social Media Links */}
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton component={MuiLink} href="https://www.facebook.com" target="_blank" color="inherit">
            <Facebook />
          </IconButton>
          <IconButton component={MuiLink} href="https://www.youtube.com" target="_blank" color="inherit">
            <YouTube />
          </IconButton>
          <IconButton component={MuiLink} href="https://www.instagram.com" target="_blank" color="inherit">
            <Instagram />
          </IconButton>
          <IconButton component={MuiLink} href="https://www.twitter.com" target="_blank" color="inherit">
            <Twitter />
          </IconButton>
        </Box>
      </Box>
    <AppBar position={trigger ? 'fixed' : 'static'} sx={{ background: 'linear-gradient(90deg, #1f4037, #99f2c8)' }}>
      <Toolbar sx={{ justifyContent: 'space-between', padding: '0 20px' }}>
        {/* Logo and Company Name */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton edge="start" color="inherit" aria-label="logo" sx={{ mr: 1 }}>
            <img src={logo} alt="Infokshetra Technologies Logo" style={{ width: 40, height: 40 }} />
          </IconButton>

          <Typography
            variant="h6"
            sx={{
              display: { xs: 'block', md: 'block' }, // Show company name on all screens
              fontSize: { xs: '1rem', md: '1.5rem' }, // Adjust font size for small screens
              fontWeight: 600,
              color: '#fff',
              transition: 'all 0.3s ease', // Smooth hover effect
              '&:hover': {
                color: '#99f2c8',
              }
            }}
          >
            Prarthana PU Science College,Bagalkot
          </Typography>
        </Box>

        {/* Links for desktop view with Icons */}
        <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
          <Button color="inherit" component={Link} to="/" startIcon={<HomeIcon />} sx={navButtonStyle}>
            Home
          </Button>

          <Button
            color="inherit"
            onClick={handleMenuClick}
            endIcon={<KeyboardArrowDownIcon />}
            startIcon={<InfoIcon />}
            sx={navButtonStyle}
          >
            About
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            MenuListProps={{ 'aria-labelledby': 'about-button' }}
          >
            <MenuItem component={Link} to="/about-company" onClick={handleMenuClose}>
              About Company
            </MenuItem>
            <MenuItem component={Link} to="/about-team" onClick={handleMenuClose}>
              Our Team
            </MenuItem>
          </Menu>

          <Button color="inherit" component={Link} to="/services" startIcon={<ServicesIcon />} sx={navButtonStyle}>
            Services
          </Button>
          <Button color="inherit" component={Link} to="/contact" startIcon={<ContactIcon />} sx={navButtonStyle}>
            Contact
          </Button>
{/*           <Button color="inherit" component={Link} to="/admin/carousel" startIcon={<ContactIcon />} sx={navButtonStyle}>
          Carousel
          </Button> */}
          <Button color="inherit" component={Link} to="/admin/login" startIcon={<VpnKeyIcon />} sx={navButtonStyle}>
            Admin Login
          </Button>
        </Box>

        {/* Menu Icon for mobile view */}
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          sx={{ display: { xs: 'block', sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>

      </Toolbar>

      {/* Drawer for mobile view */}
       {/* Drawer for mobile view */}
       <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List sx={{ width: 250 }}>
          <ListItem button component={Link} to="/" onClick={toggleDrawer(false)}>
            <ListItemIcon><HomeIcon /></ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>

          {/* About with Expandable Submenu for Mobile */}
          <ListItem button onClick={handleAboutClick}>
            <ListItemIcon><InfoIcon /></ListItemIcon>
            <ListItemText primary="About" />
            {aboutOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={aboutOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/about-company" onClick={toggleDrawer(false)}>
                <ListItemText inset primary="About Company" />
              </ListItem>
              <ListItem button component={Link} to="/about-team" onClick={toggleDrawer(false)}>
                <ListItemText inset primary="Our Team" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button component={Link} to="/services" onClick={toggleDrawer(false)}>
            <ListItemIcon><ServicesIcon /></ListItemIcon>
            <ListItemText primary="Services" />
          </ListItem>
          <ListItem button component={Link} to="/contact" onClick={toggleDrawer(false)}>
            <ListItemIcon><ContactIcon /></ListItemIcon>
            <ListItemText primary="Contact" />
          </ListItem>
         {/*  <ListItem button component={Link} to="/admin/carousel" onClick={toggleDrawer(false)}>
            <ListItemIcon><ContactIcon /></ListItemIcon>
            <ListItemText primary="Carousel" />
          </ListItem> */}
          <ListItem button component={Link} to="/admin/login" onClick={toggleDrawer(false)}>
            <ListItemIcon><VpnKeyIcon /></ListItemIcon>
            <ListItemText primary="Admin Login" />
          </ListItem>
        </List>
      </Drawer>
    </AppBar>
    </>
  );
};

// Styling for the Navbar buttons
const navButtonStyle = {
  color: '#fff',
  fontWeight: 'bold',
  textTransform: 'none',
  marginLeft: '10px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: '#99f2c8',
  }
};

export default Navbar;