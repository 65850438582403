import React from 'react';
import { Fab, useScrollTrigger, Zoom, Typography, Box, IconButton, Grid } from '@mui/material';
import { KeyboardArrowUp as ArrowUpIcon, Instagram, WhatsApp, YouTube, Facebook, Twitter } from '@mui/icons-material';

const ScrollTop = ({ children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Zoom in={trigger}>
      <Fab
        onClick={handleClick}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
        color="primary"
        aria-label="scroll back to top"
      >
        {children}
      </Fab>
    </Zoom>
  );
};

const Footer = () => {
  return (
    <>
      {/* Footer Content */}
      <footer style={{ padding: '20px', background: '#333', color: '#fff', marginTop: '50px' }}>
        {/* Social Media Icons */}
        <Box sx={{ textAlign: 'center', mb: 2 }}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item>
              <IconButton
                component="a"
                href="https://www.instagram.com/prarthanapucollege/"
                target="_blank"
                rel="noopener"
                aria-label="Instagram"
                sx={{ color: '#fff' }}
              >
                <Instagram />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                component="a"
                href="https://wa.me/yourwhatsapplink"
                target="_blank"
                rel="noopener"
                aria-label="WhatsApp"
                sx={{ color: '#fff' }}
              >
                <WhatsApp />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                component="a"
                href="https://www.youtube.com"
                target="_blank"
                rel="noopener"
                aria-label="YouTube"
                sx={{ color: '#fff' }}
              >
                <YouTube />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                component="a"
                href="https://www.facebook.com/p/Prarthana-PU-Science-College-Bagalkot-100069751296584/"
                target="_blank"
                rel="noopener"
                aria-label="Facebook"
                sx={{ color: '#fff' }}
              >
                <Facebook />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                component="a"
                href="https://twitter.com"
                target="_blank"
                rel="noopener"
                aria-label="Twitter"
                sx={{ color: '#fff' }}
              >
                <Twitter />
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        {/* Contact Details */}
        <Typography variant="body1" color="inherit" align="center">
          Contact Us: +91 7947109498 | prarthanaprincipal@gmail.com
        </Typography>

        {/* Copyright Info */}
        <Typography variant="body2" color="inherit" align="center" sx={{ mt: 2 }}>
          &copy; {new Date().getFullYear()} Prarthana PU Science College. All rights reserved.
        </Typography>
      </footer>

      {/* Scroll-to-Top Button */}
      <ScrollTop>
        <ArrowUpIcon />
      </ScrollTop>
    </>
  );
};

export default Footer;
