// src/admin/AdminLiteDashboard.js
import React, { useState } from 'react';
import {
    AppBar, Toolbar, Typography, Button, Drawer, List, ListItem, ListItemText, Box,
    CssBaseline, IconButton, Avatar, ListItemIcon, Divider
} from '@mui/material';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import ImageIcon from '@mui/icons-material/Image';
import SchoolIcon from '@mui/icons-material/School';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CampaignIcon from '@mui/icons-material/Campaign';
/* import { QuestionAnswer } from '@mui/icons-material';
import { Search } from '@mui/icons-material'; */
import { Assignment } from '@mui/icons-material';
const drawerWidth = 240;

const AdminLiteDashboard = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState(false); // State to manage drawer open/close

    // Logout function
    const handleLogout = () => {
        navigate('/admin/login'); // Redirect to admin login page
    };

    // Toggle drawer function
    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* AppBar styled similar to AdminLTE top nav */}
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: '#343a40' }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        edge="start"
                        sx={{ mr: 2, display: { sm: 'none' } }} // Show only on mobile
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
                        Admin Dashboard
                    </Typography>
                    <Button color="inherit" onClick={handleLogout}>
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>

            {/* Sidebar Drawer */}
            <Drawer
                variant="temporary" // Mobile drawer
                open={open}
                onClose={toggleDrawer}
                ModalProps={{ keepMounted: true }} // Better open performance on mobile
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box', bgcolor: '#343a40', color: '#ffffff' },
                }}
            >
                <SidebarContent currentLocation={location} />
            </Drawer>

            {/* Permanent Sidebar for larger screens */}
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box', bgcolor: '#343a40', color: '#ffffff' },
                }}
                open
            >
                <SidebarContent currentLocation={location} />
            </Drawer>

            {/* Main Content Area */}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    bgcolor: '#f4f6f9', // AdminLTE main area background color
                    width: { xs: '100%', sm: `calc(100% - ${drawerWidth}px)` },
                    minHeight: '100vh'
                }}
            >
                <Toolbar />
                <Outlet /> {/* Render the respective component based on the route */}
            </Box>
        </Box>
    );
};

// Sidebar Content component
const SidebarContent = ({ currentLocation }) => (
    <Box>
        <Toolbar />
        <Box sx={{ padding: 2, textAlign: 'center' }}>
            <Avatar alt="Admin" src="/path-to-your-avatar.jpg" sx={{ width: 64, height: 64, marginBottom: 2 }} />
            <Typography variant="h6">Admin Name</Typography>
            <Typography variant="body2" color="textSecondary">Administrator</Typography>
        </Box>
        <Divider sx={{ bgcolor: '#ffffff' }} />
        <List>
            <SidebarItem
                to="/admin/home"
                label="Dashboard"
                icon={<HomeIcon />}
                active={currentLocation.pathname === '/admin/home'}
            />
            <SidebarItem
                to="/admin/carousel"
                label="Carousel Images"
                icon={<ImageIcon />}
                active={currentLocation.pathname === '/admin/carousel'}
            />
            <SidebarItem
                to="/admin/courses"
                label="Courses"
                icon={<SchoolIcon />}
                active={currentLocation.pathname === '/admin/courses'}
            />
             <SidebarItem
                to="/admin/notifications"
                label="Notification"
                icon={<NotificationsIcon />}
                active={currentLocation.pathname === '/admin/notifications'}
            />
             <SidebarItem
                to="/admin/advirtise"
                label="Advirtise"
                icon={<CampaignIcon />}
                active={currentLocation.pathname === '/admin/advirtise'}
            />
            <SidebarItem
                to="/admin/inquiry"
                label="Inquiry"
                icon={<Assignment />}
                active={currentLocation.pathname === '/admin/inquiry'}
            />
        </List>
    </Box>
);

// Sidebar Item Component for each menu item with active state styling
const SidebarItem = ({ to, label, icon, active }) => (
    <ListItem
        button
        component={Link}
        to={to}
        sx={{
            color: active ? '#ffffff' : '#bdbdbd',
            fontWeight: active ? 'bold' : 'normal',
            backgroundColor: active ? '#495057' : 'transparent',
            pl: active ? 3 : 2, // Adds left padding for active items
            borderLeft: active ? '4px solid #ffffff' : 'none', // Left border for active items
            '&:hover': {
                backgroundColor: '#495057',
                color: '#ffffff',
                borderLeft: '4px solid #ffffff',
            },
        }}
    >
        <ListItemIcon sx={{ color: active ? '#ffffff' : '#bdbdbd', minWidth: 40 }}>
            {icon}
        </ListItemIcon>
        <ListItemText primary={label} />
    </ListItem>
);

export default AdminLiteDashboard;
