import React, { useState } from 'react';
import { Container, TextField, Button, Grid, Typography } from '@mui/material';
import axios from 'axios';

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', mobile: '', message: '' });
  const [errors, setErrors] = useState({});

  const validate = () => {
    let tempErrors = {};
    tempErrors.name = formData.name ? "" : "Name is required.";
    tempErrors.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(formData.email) ? "" : "Email is not valid.";
    tempErrors.mobile = /^\d{10}$/.test(formData.mobile) ? "" : "Mobile number should be 10 digits.";
    tempErrors.message = formData.message ? "" : "Message is required.";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        await axios.post('https://apiviztech.in:6100/api/inquiries', formData);
        console.log('Form submitted', formData);
        setFormData({ name: '', email: '', mobile: '', message: '' });
        alert("Inquiry submitted successfully!");
      } catch (error) {
        console.error("Error submitting form", error);
      }
    }
  };

  return (
    <Container>
      <Typography variant="h4" sx={{ mt: 4 }}>Contact Us</Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.mobile}
              helperText={errors.mobile}
              inputProps={{ maxLength: 10 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              fullWidth
              multiline
              rows={4}
              required
              error={!!errors.message}
              helperText={errors.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">Submit</Button>
          </Grid>
        </Grid>
      </form>

      <Typography variant="h6" sx={{ mt: 4 }}>Our Address</Typography>
      <Typography>Prarthana PU Science College</Typography>
      <Typography>Daddennavar Nursing Campus, Bagalkote-587101</Typography>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15327.228327121222!2d75.690504!3d16.178896!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc778f3349a462f%3A0x27050572c74ff80a!2sPrarthana%20P%20U%20Science%20College%20Bagalkot!5e0!3m2!1smr!2sin!4v1730880861023!5m2!1smr!2sin"
        width="100%"
        height="300"
        style={{ border: 0, marginTop: 16 }}
        allowFullScreen=""
        loading="lazy"
        title="Google Map"
      ></iframe>



    </Container>
  );
};

export default Contact;
