// src/App.js
import React, { useEffect } from 'react';
import { HashRouter as Router, Routes, Route,Outlet  } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Carousel from './admin/carousel';
import CourseManager from './admin/CourseManager';
import AdminLogin from './admin/adminlogin';
import Register from './admin/Register';
import AdminLiteDashboard from './admin/AdminLiteDashboard';
import Notification from './admin/NotificationPage';
import NotificationDetail from './NotificationDetail';
import Advirtise from './admin/Advertise';
import Inquiry from './admin/inquiry'; 

function App() {
  /* useEffect(() => {
    // Disable right-click across the application
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    // Add the event listener when the component mounts
    document.addEventListener('contextmenu', handleContextMenu);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []); */
  return (
    <Router>
      <Routes>
    {/*   <Navbar /> */}
     {/*  <Routes> */}
        {/* <Route path="/" element={<Home />} /> */}
        <Route element={<Layout/>}>
          {/* <Navbar/> */}
         
          <Route path="/" element={<Home />} />
          <Route path="/:id" element={<Home />} />  {/* Dynamic route for advertisement ID */}
        {/* Fallback route to redirect to home if no route matches */}
        <Route path="*" element={<Home />} />
        </Route>
        <Route element={<Layout/>}>
        <Route path="/about-company" element={<About />} />
        </Route>
        <Route path="/services" element={<Services />} />
        <Route element={<Layout/>}>
        <Route path="/contact" element={<Contact />} />
        </Route>
        <Route element={<AdminLiteDashboard/>}>
        <Route path="/admin/carousel" element={<Carousel />} />
        </Route>
        <Route element={<AdminLiteDashboard/>}>
        <Route path="/admin/courses" element={<CourseManager />} />
        </Route>
        <Route exact path="/admin/login" element={<AdminLogin/>} />
        <Route path="/admin/register" element={<Register/>} />
        <Route path="/admin/home" element={<AdminLiteDashboard />} />
        <Route element={<AdminLiteDashboard/>}>
        <Route path="/admin/notifications" element={<Notification />} />
        <Route path="/admin/notifications/:notificationId" element={<Notification />} />
        </Route>
        <Route element={<Layout/>}>
        <Route path="/notification-detail/:title" element={<NotificationDetail />} />
        </Route>
        <Route element={<AdminLiteDashboard/>}>
        <Route path="/admin/advirtise" element={<Advirtise />} />
        </Route>
        <Route element={<AdminLiteDashboard/>}>
        <Route path="/admin/inquiry" element={<Inquiry />} />
        </Route>
      {/* </Routes> */}
      {/* <Footer /> */}
      </Routes>
    </Router>
  );
}
// Layout component to conditionally render Navbar and Footer
const Layout = () => {
  return (
      <>
          <Navbar /> {/* Include Navbar */}
          <main>
              <Outlet /> {/* This will render the child routes */}
          </main>
          <Footer /> {/* Include Footer */}
      </>
  );
};
export default App;