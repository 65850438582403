// src/NotificationDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import axios from 'axios';

const NotificationDetail = () => {
    const { title } = useParams();
    console.log(title);
    const [notification, setNotification] = useState(null);

    useEffect(() => {
        //console.log('Fetching notification for title:', title); // Log the title
        const fetchNotification = async () => {
            try {
                const response = await axios.get(`https://apiviztech.in:6100/api/notificationsd/${encodeURIComponent(title)}`);
                setNotification(response.data);
            } catch (error) {
                console.error('Error fetching notification:', error);
            }
        };
        fetchNotification();
    }, [title]);

    return (
        <Container>
            {notification ? (
                <>
                    <Typography variant="h4">{notification.title}</Typography>
                    <Typography variant="body1">{notification.content}</Typography>
                </>
            ) : (
                <Typography variant="h6">Loading...</Typography>
            )}
        </Container>
    );
};

export default NotificationDetail;
