// src/admin/adminlogin.js

import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './AdminLogin.css'; // Import your styles here
import axios from 'axios';
import { Link } from 'react-router-dom';
const AdminLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [loginError, setLoginError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async  (e) => {
        e.preventDefault();
        const validationErrors = {};
        
        // Validate username/email
        if (!username) {
            validationErrors.username = 'Username or Email is required';
        }
        
        // Validate password
        if (!password) {
            validationErrors.password = 'Password is required';
        }

        // If there are errors, update the state and return
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

       /*  // Proceed with login logic (e.g., API call)
        console.log('Logging in with:', { username, password });

        // Reset errors on successful login attempt
        setErrors({}); */
        try {
            const response = await axios.post('https://apiviztech.in:6100/api/admin/login', {
                email: username,
                password: password
            });

            if (response.data.success) {
                navigate('/admin/home'); // Redirect to admin home page on successful login
            }
        } catch (error) {
            setLoginError('Invalid email or password');
        }
        
    };

    return (
        <div className="admin-login-page">
      <div className="admin-login-container">
        <Container maxWidth="xs" sx={{ marginTop: 4 }}>
            <Typography variant="h5" component="h1" gutterBottom>
                Admin Login
            </Typography>
            <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Username or Email"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    error={!!errors.username}
                    helperText={errors.username}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={!!errors.password}
                    helperText={errors.password}
                />
                <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
                    Log In
                </Button>
               {/*  <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                    <Link href="/register" variant="body2">
                        Create Account
                    </Link>
                </Typography>
                <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                    <Link href="/" variant="body2">
                        Home
                    </Link>
                </Typography> */}
                {/* Links side by side */}
                {loginError && (
                            <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>
                                {loginError}
                            </Typography>
                        )}
                <Box
                            sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}
                        >
                           {/*  <Link href="/admin/register" variant="body2">
                                Create Account
                            </Link> */}
                            <Link to="/admin/register" variant="body2">
    Create Account
</Link>
                            {/* <Link href="/" variant="body2"> */}
                            <Link to="/" variant="body2">
                                Home
                            </Link>
                        </Box>
            </Box>
        </Container>
        </div>
        </div>
    );
};

export default AdminLogin;
