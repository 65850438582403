import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { Edit, Delete, Share } from "@mui/icons-material"; // Add Share icon
import axios from "axios";

const Advertise = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [advertisements, setAdvertisements] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentAdId, setCurrentAdId] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchAdvertisements();
  }, []);

  const fetchAdvertisements = async () => {
    const response = await axios.get("https://apiviztech.in:6100/api/advertisements");
    setAdvertisements(response.data);
  };

  const handleImageChange = (e) => {
    setImages([...e.target.files]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    images.forEach((image) => formData.append("images", image));

    try {
      if (isEditing) {
        await axios.put(`https://apiviztech.in:6100/api/advertisements/${currentAdId}`, formData);
      } else {
        await axios.post("https://apiviztech.in:6100/api/advertisements", formData);
      }
      fetchAdvertisements();
      resetForm();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const resetForm = () => {
    setTitle("");
    setDescription("");
    setImages([]);
    setIsEditing(false);
    setCurrentAdId(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleEdit = (ad) => {
    setTitle(ad.title);
    setDescription(ad.description);
    setImages([]);
    setIsEditing(true);
    setCurrentAdId(ad.ad_id);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDelete = async (id) => {
    await axios.delete(`https://apiviztech.in:6100/api/advertisements/${id}`);
    fetchAdvertisements();
  };

  const handleShare = (title, id) => {
    const message = `Check out this advertisement: ${title}\nhttps://apponixtech.viztech.in/#/${id}`;
    /* const whatsappURL = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, "_blank"); */
    const isMobile = /iPhone|iPad|iPod|Android|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const whatsappURL = isMobile
    ? `whatsapp://send?text=${encodeURIComponent(message)}`
    : `https://web.whatsapp.com/send?text=${encodeURIComponent(message)}`;

  window.open(whatsappURL, "_blank");
};
    
  

  return (
    <div style={{ padding: "20px" }}>
      <h2>Advertise</h2>
      <form onSubmit={handleSubmit} style={{ marginBottom: "20px" }}>
        <TextField
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          required
          margin="normal"
        />
        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          multiline
          rows={4}
          required
          margin="normal"
        />
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageChange}
          ref={fileInputRef}
          style={{ marginTop: "15px", marginBottom: "15px" }}
        />
        <Button variant="contained" color="primary" type="submit" fullWidth>
          {isEditing ? "Update" : "Submit"}
        </Button>
      </form>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Images</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {advertisements.map((ad) => (
              <TableRow key={ad.ad_id}>
                <TableCell>{ad.title}</TableCell>
                <TableCell>{ad.description}</TableCell>
                <TableCell>
                  {ad.images && JSON.parse(ad.images).map((img, index) => (
                    <img
                      key={index}
                      src={`https://apiviztech.in:6100${img}`}
                      alt="ad"
                      width="50"
                      style={{ marginRight: "5px" }}
                    />
                  ))}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(ad)}>
                    <Edit color="primary" />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(ad.ad_id)}>
                    <Delete color="secondary" />
                  </IconButton>
                  <IconButton onClick={() => handleShare(ad.title, ad.ad_id)}>
                    <Share color="success" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default Advertise;
